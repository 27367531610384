import React from "react"

import Styled from "styled-components"

import { rhythm } from "../utils/typography"

const Container = Styled.h2`
  font-size: ${rhythm(0.8)};
  font-family: ${props => props.font};
  font-weight: 700;
  text-align: center;
`

const Subtitle = ({ children, font }) => {
  font = font === 'lily' ? `LilyScriptOne-Regular` : `Montserrat,sans-serif`
  return <Container font={font}>{children}</Container>
}

export default Subtitle
