import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import ContentBlock from "../components/contentBlock"
import BreadcrumbsBar from "../components/breadcrumbsBar"
import Breadcrumbs from "../components/breadcrumbs"
import Title from "../components/title"
import Subtitle from "../components/subtitle"

class PaginasWebPosicionamientoPublicidad extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Diseño Web + Posicionamiento + Publicidad" />

        <ContentBlock theme="redOrange" angle>
          <BreadcrumbsBar>
            <Breadcrumbs name="FeedYourWeb" page="/" color="#fff" />
            <Breadcrumbs
              name="Diseño Web + Posicionamiento + Publicidad"
              page="/paginas-web-posicionamiento-publicidad"
              color="#fff"
            />
          </BreadcrumbsBar>
          <Title className="light-red">
            Diseño Web <br />
            Posicionamiento <br />
            Publicidad
          </Title>
          <p>Los clientes contactan cuando necesitan ayuda con:</p>
        </ContentBlock>

        <ContentBlock theme="white">
          <Subtitle classname="dark-gray">Desarrollo Web & Ecommerce</Subtitle>
          <ol>
            <li>
              Construir una página web nueva y responsiva con&nbsp;Wordpress
            </li>
            <li>
              Customizar un theme de WordPress, cambiar diseño o añadir una
              nueva funcionalidad front/back
            </li>
            <li>
              Crear una tienda online – “e-commerce” usando Prestashop o
              WooCommerce
            </li>
            <li>Desarrollar o customizar un plugin de WordPress</li>
            <li>
              Adaptar un sitio web existente para que se vea bien en móviles,
              tabletas (responsivo), añadir nuevos idiomas/traducciones,
              solucionar cualquier problema o bug
            </li>
          </ol>
        </ContentBlock>

        <ContentBlock theme="gray">
          <Subtitle className="dark-gray">
            Posicionamiento | Anuncios en Google | Mail marketing | Copywriting | alta en directorios
          </Subtitle>
          <ol>
            <li>Posicionar un página web / subir su posición en Google</li>
            <li>Llevar campañas de publicidad en google (Adwords)</li>
            <li>Escribir textos para una página web o blog</li>
            <li>Enviar campañas de Mail Marketing usando una lista de clientes</li>
            <li>Dar de alta el negocio en directorios de empresas</li>
          </ol>
        </ContentBlock>
      </Layout>
    )
  }
}

export default PaginasWebPosicionamientoPublicidad

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
